import Immutable from "immutable";
import {push} from "connected-react-router";
import DuckHelpers from "helpers/DuckHelpers.js";

// Action Creators
export const actions = {

  /**
  * Changes the store-side url values without changing the url. Used when initializing.
  * @param {object} paramObject Object containing key:value pairs to be changed in the url.
  *   Invalid keys throw an error! (Unsafe)
  */
  updateURLParams: (paramObject) => {
    return {
      type: "UPDATE-URL-PARAMS",
      paramObject
    };
  },

  /**
  * Changes store url param key to value and then pushes a new url based on the store params.
  * @param {string} key The key of the param to be changed. Invalid key throws an error! (Unsafe)
  * @param {string} value The value to change the param to.
  */
  updateURL: (sKey, value) => {
    return (dispatch, getState) => {
      if (sKey) {
        let params = {};
        params[sKey] = value;
        dispatch(actions.updateURLParams(params));
      }
      let rStore = getState().URLDuck;
      let url = DuckHelpers.urlBase();
      let once = true;
      rStore.keySeq().forEach((key) => {
        if (rStore.get(key) !== initialState.get(key)) {
          if (once) {
            if (key === "nav") {
              url = url + "/?" + key + "=" + rStore.get(key).join("/");
            } else {
              url = url + "/?" + key + "=" + rStore.get(key);
            }
            once = false;
          } else {
            if (key === "nav") {
              url = url + "&" + key + "=" + rStore.get(key).join("/");
            } else {
              url = url + "&" + key + "=" + rStore.get(key);
            }
          }
        }
      });
      dispatch(push(url));
    };
  }
};

// Reducers
export const initialState = Immutable.Map({
  video: null, // id of video in url
  live: null, // id of livestream in url
  view: 31, // view for widget. Each bit toggles a component in the outer window as follows:
  // 1  - Video Section
  // 2  - Sidebar
  // 4  - Video Info
  // 8  - BrowsingMenu
  // 16 - BrowsingSection
  noautoplay: 0, // autoplay value in url
  nav: null, // navigation path
  volume: 1, // volume
  schedule: 0, // display as embedded schedule if 1
  loop: 0 // whether to loop queue or not
});
export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE-URL-PARAMS":
      let returnState = state;
      for (var key in action.paramObject) {
        returnState = returnState.set(key, action.paramObject[key]);
      }
      return returnState;
    default:
      return state;
  }
};
