// vim: et sw=2 ts=2 sts=2
import Immutable from "immutable";
import helpers from "helpers/DuckHelpers.js";

// Action Creators
export const actions = {

  /**
  * Toggles the video info modal and makes it display information about a specific video.
  * @param {number} [index=-1] the index of the video to display information about within displayItems
  */
  toggleInfoModal: function (index = -1) {
    return {
      type: "TOGGLE-INFO-MODAL",
      index
    };
  },

  /**
  * Toggles the visibility of the whole browsing section.
  */
  toggleBrowsingSection: function () {
    return {
      type: "TOGGLE-BROWSING-SECTION"
    };
  },

  /**
  * Get total number of media items
  */
  getTotalItemsAsync: function (query = null) {
    return async (dispatch, getState) => {
      let url = "/api/v1/vod/file_list";
      if (query && typeof query === "string") url += ("/" + query);
      try {
        let res = await fetch(url);
        if(!res.ok) {
          let errText = await res.text();
          throw new Error(`${res.status}: ${errText}`);
        }
        let data = await res.json();
        let path = query ?
          ["displayItems", "search", query, "paging", "total"] :
          ["displayItems", "recent", "paging", "total"];
        dispatch(actions.getTotalItems(data.number_of_files, path));
      } catch (err) {
        console.warn("Error encountered fetching item count, cannot display total pages:\n" + err );
      }
    };
  },

  getTotalItems: function (count, path) {
    return {
      type: "GET-TOTAL-ITEMS",
      count,
      path
    };
  },

  hideBackButton: function () {
    return {
      type: "HIDE-BACK-BUTTON"
    };
  }

};

// Reducers
/**
* @property {object} displayItems A map containing content information for each given nav path
* @property {array} displayItems.<navpath>.list The list of items displayed at the given nav path.
* @property {object} displayItems.<navpath>.paging The pagination info for the given nav path
* @property {array} navPath In-system navigation path, used for determining behaviour of items.
* @property {array} playingItem The currently playing item.
* @property {array} breadCrumbs What bread crumbs to display.
* @property {boolean} hidden Whether or not to hide the BrowsingSection.
* @property {boolean} display Whether to display video info modal or not.
* @property {boolean} infoFile The index of the item in displayItems to display info in the video info modal for.
*/
export const initialState = Immutable.Map({
  displayItems: Immutable.Map({}), // Map containing list of items to display
  //   and pagination info for each given navPath.
  // Each item is an object with the following props:
  //   title: the name to be displayed for the item. Could be item's name, title, program, etc.
  //   description: a secondary blurb to go under the title. Could be item's episode, description, etc.
  //   rpath: rpath of the item. .thumb.jpg can be added to get the item's thumbnail.
  //   thumbnailRpath: rpath of the thumbnail.
  //   (optional) guid: globally unique identifier. Only for files.
  //   (optional) selected: whether the item is being played or not.
  //                        For videos, but not programs and playlists (as these link to a new url.)
  //   (optional) meta: metadata. Only for files.
  navPath: Immutable.List(["recent"]), // in-system navigation path, used for determining behaviour of items
  playingItem: Immutable.List([]), // the currently playing item
  breadCrumbs: Immutable.List(["recent"]), // what breadcrumbs should display
  hidden: false, // whether or not to hide the BrowsingSection
  display: false, // whether to display video info modal or not
  infoFile: -1, // index of item in DisplayItems to display info for
  perPage: 500, // The number of items to be displayed per page
  backButtonShow: true // Whether the back button should be shown or not
});
export default (state = initialState, action) => {
  switch (action.type) {
    case "NAVIGATE": {
      let navRoute = ["displayItems", ...action.path];
      let page = action.page ? action.page : 1;
      return state.set("navPath", Immutable.List(action.path))
        .set("breadCrumbs", Immutable.List(action.path))
        .setIn([...navRoute, "paging", "page"], page);
    }
    case "LOAD-VIDEO-DATA": {
      let navRoute = ["displayItems", ...action.path];
      let page = action.page ? action.page : 1;
      let start;
      if (action.data) {
        switch (action.path[0]) {
          case "recent": {
            let newDisplayItems = Immutable.List(action.data.map((element) => {
              if(element.metadata && !element.meta) {
                element.meta = element.metadata
              }
              return helpers.constructDisplayFile(element);
            }));
            if (!state.getIn([...navRoute, "list"])) {
              state = state.setIn([...navRoute, "list"], Immutable.List([]));
            }
            start = (page - 1) * state.get("perPage");
            return state.setIn(
              [...navRoute, "list"],
              helpers.mergeInBrowsingItems(
                state.getIn([...navRoute, "list"]),
                newDisplayItems,
                start,
                state.get("perPage"))
            );
          }
          case "programs": {
            if (!state.getIn([...navRoute, "paging", "total"])) {
              state = state.setIn([...navRoute, "paging", "total"],
                (action.data.items ?
                  action.data.items.length :
                  action.data.length));
            }
            if (action.path[1]) {
              let newDisplayItems = Immutable.List(action.data.items.map((element) => {
                return helpers.constructDisplayFile(element);
              }));
              return state.setIn([...navRoute, "list"], Immutable.List(newDisplayItems));
            } else {
              let newDisplayPrograms = Immutable.List(action.data.map((element) => {
                return helpers.constructDisplayProgram(element, element.count);
              }));
              return state.setIn([...navRoute, "list"], Immutable.List(newDisplayPrograms));
            }
          }
          case "playlists": {
            if (!state.getIn([...navRoute, "paging", "total"])) {
              state = state.setIn([...navRoute, "paging", "total"], action.data.length);
            }
            if (action.path[1]) {
              let newDisplayItems = Immutable.List(action.data.map((element, index) => {
                let listFile = helpers.constructDisplayFile(element);
                return listFile.set("key", listFile.get("key") + index);
              }));
              return state.setIn([...navRoute, "list"], Immutable.List(newDisplayItems));
            } else {
              let newDisplayPlaylists = Immutable.List(action.data.map((element) => {
                return helpers.constructDisplayPlaylist(element);
              }));
              return state.setIn([...navRoute, "list"], Immutable.List(newDisplayPlaylists));
            }
          }
          case "search": {
            let newDisplayItems = Immutable.List(action.data.map((element) => {
              if(element.metadata && !element.meta) {
                element.meta = element.metadata
              }
              if (element.meta["*playlist"]) {
                return helpers.constructDisplayPlaylist(element);
              } else {
                return helpers.constructDisplayFile(element);
              }
            }));
            if (!state.getIn([...navRoute, "list"])) {
              state = state.setIn([...navRoute, "list"], Immutable.List([]));
            }
            start = (page - 1) * state.get("perPage");
            return state.setIn(
              [...navRoute, "list"],
              helpers.mergeInBrowsingItems(
                state.getIn([...navRoute, "list"]),
                newDisplayItems,
                start,
                state.get("perPage"))
            );
          }
          default:
            break;
        }
      }
      return state;
    }
    case "PLAY-VIDEO":
      if (window.innerWidth < 600) {
        state = state.set("hidden", true);
      }
      return state.set("playingItem", Immutable.List([action.data.guid]));
    case "PLAY-LIVE":
      if (window.innerWidth < 600) {
        state = state.set("hidden", true);
      }
      return state;
    case "TOGGLE-INFO-MODAL":
      return state.set("display", !state.get("display"))
        .set("infoFile", action.index);
    case "TOGGLE-BROWSING-SECTION":
      return state.set("hidden", !state.get("hidden"));
    case "GET-TOTAL-ITEMS":
      return state.setIn(action.path, action.count);
    case "SET-TOTAL":
      if (state.getIn(["displayItems", ...action.path, "list"]) &&
      !state.getIn(["displayItems", ...action.path, "paging", "total"])) {
        state = state.setIn(["displayItems", ...action.path, "paging", "total"],
          state.getIn(["displayItems", ...action.path, "list"]).size);
      }
      return state;
    case "HIDE-BACK-BUTTON":
      return state.set("backButtonShow", false);
    default:
      return state;
  }
};
