import Immutable from "immutable";
import uuid from "uuid";

import TEST_SCHEDULE from "test/sample-values/schedule.json";

var LoaderActions = require("./LoaderDuck.js").actions;

const DAYTOSECONDS = 60 * 60 * 24;
const HOURTOMILLIS = 3600000;

// Action Creators
export const actions = {
  /*
  * @function retrieveItemsAsync
  * @param {number} channel The channel number to retrieve schedule data from the server for.
  * @description Asynchronous function that retrieves schedule data from the server for a given channel
  * and then passes that data to the store through a synchronous callback. Data is processed by converting
  * to immutable, combining metadata into the corresponding schedule items, and then calculating a display name.
  */
  retrieveItemsAsync: function (channel) {
    return async (dispatch, getState) => {
      if(process.env.NODE_ENV === "development") {
        dispatch(actions.retrieveItems(TEST_SCHEDULE, channel));
        return
      }
      dispatch(LoaderActions.reportOut());
      let res = await fetch("/files/schedule.cgi?ch=" + channel + "&what=read")
      dispatch(LoaderActions.reportIn());
      if(res.ok) {
        let data = await res.json();
        dispatch(actions.retrieveItems(data, channel));
      } else {
        let error = await res.text();
        console.error("Error retrieving schedule: " + error);
        dispatch(actions.scheduleLoadError(channel));
        throw error
      }
    };
  },

  /*
   * @function updateViewers
   * Updates the number of view data for each live stream
   */
  updateViewers: function () {
    return async (dispatch, getState) => {
      let res = await fetch("/api/v1/vod/live-watch")
      if(res.ok &&
        res.headers.get("Content-Type") &&
        res.headers.get("Content-Type").includes("application/json")) {
        let data = await res.json();
        let viewership = {};
        data.views.forEach(viewObject => { viewership[viewObject.rpath] = viewObject.views; });
        dispatch(actions.setLiveViewers(Immutable.fromJS(viewership)));
      } else {
        let error = await res.text();
        console.error("Error retrieving live viewers: " + error);
        throw error
      };
    };
  },

  /*
  * @function retrieveItems
  * @param {object} data Data passed from asynchronous function
  * @param {string} channel The channel that the data is about.
  * @returns adds data to state under serverData[channelName].
  */
  retrieveItems: function (data, channel) {
    return {
      type: "RETRIEVE-ITEMS",
      data,
      channel
    };
  },

  /*
  * @function changeView
  * @param {object} newView The new view to display. Views are in the format {viewType, [date, channel]/[query]}.
  * viewType is either 'search' or 'date'. For search, the param query is required to be a string of the search query.
  * For date, the param channel is required as the channel number and date is required as the date in linux time.
  * @returns changes currentView to newView and then populates displaySchedule with the items of the given
  * view. Dispatches different actions for search and date for the sake of simplicity.
  */
  changeView: function (newView) {
    return (dispatch, getState) => {
      if (newView.viewType === "date") {
        if (getState().ScheduleDuck.getIn(["configData", newView.channel]) &&
          getState().ScheduleDuck.getIn(["configData", newView.channel, "spath"]) &&
          getState().ScheduleDuck.getIn(["serverData", newView.channel]) &&
          getState().ScheduleDuck.getIn(["serverData", newView.channel]).isEmpty()
        ) {
          dispatch(actions.retrieveItemsAsync(newView.channel)).then(() => {
            newView["force_date"] = true;
            dispatch(actions.changeViewDate(newView));
          }).catch(() => {
          });
        }
        dispatch(actions.changeViewDate(newView));
      } else if (newView.viewType === "search") {
        getState().ScheduleDuck.getIn(["serverData"]).forEach((value, key) => {
          if (value.isEmpty()) {
            dispatch(actions.retrieveItemsAsync(key)).then(() => {
              dispatch(actions.changeViewSearch(newView));
            });
          }
        });
        dispatch(actions.changeViewSearch(newView));
      } else {
        console.warn("Warning: Invalid view type: " + newView[0]);
      }
    };
  },

  /*
  * @function changeViewDate
  * Dispatched by changeView, should not be invoked directly
  */
  changeViewDate: function (newView) {
    return {
      type: "CHANGE-VIEW-DATE",
      newView
    };
  },

  /*
  * @function changeViewSearch
  * Dispatched by changeView, should not be invoked directly
  */
  changeViewSearch: function (newView) {
    return {
      type: "CHANGE-VIEW-SEARCH",
      newView
    };
  },

  /*
   * @function scheduleLoadError
   * Dispatched when a schedule fails to load. Designates that schedule as nonexistant.
   */
  scheduleLoadError: function (channel) {
    return {
      type: "SCHEDULE-LOAD-ERROR",
      channel
    };
  },

  /*
   * @function setLiveViewers
   * Dispatched by updateViewers. Sets the current viewership data.
   */
  setLiveViewers: function (data) {
    return {
      type: "SET-LIVE-VIEWERS",
      data
    };
  }
};

export const initialState = Immutable.Map({
  scheduleStyle: "List", // Determines which live schedule to use (List or Guide).
  configData: Immutable.Map({}), // Schedule info from config.js
  serverData: Immutable.Map({}), // Raw data retrieved from schedule.cgi, in form [channelName:dataObject].
  // Defaults and blank items are stripped.
  displaySchedule: Immutable.List([]), // List of currently displayed items. Each item should be an object
  //   containing the display name, start and end times. List should be
  //   sorted by time if viewing a date.
  currentView: Immutable.Map({}), // Current view, in format {viewType, [date, channel]/[query]}.
  //   See changeView action creator for details.
  previousDate: -1, // The last date navigated to.
  previousChannel: null, // The last channel navigated to.
  embedChannelControl: false, // Whether to allow changing channels in the embedded schedule
  embedVideoPlay: true, // Whether to allow video playing in the embedded schedule
  embedLivePlay: false, // Whether to allow live stream playing in the embedded schedule
  showSeconds: false, // Whether to show second values in live schedule item times or not
  liveViewers: Immutable.Map({}) // Object containing the number of current viewers for each live stream
});

export default (state = initialState, action) => {
  switch (action.type) {
    case "INITIALIZE-CONFIG":
      let configData = {};
      let serverData = {};
      if (action.config.livelist) {
        action.config.livelist.forEach((element) => {
          let id = element.rpath.split("ch");
          id = id[id.length - 1];
          configData[id] = element;
          serverData[id] = {};
        });
      }
      if (action.config.schedulelist) {
        action.config.schedulelist.forEach((element) => {
          let id = element.rpath.split("ch");
          id = id[id.length - 1];
          if (configData.hasOwnProperty(id)) {
            for (let key in element) {
              if (!element.hasOwnProperty(key)) continue;
              if (!configData[id].hasOwnProperty(key)) {
                configData[id][key] = element[key];
              } else if (key !== "rpath" && (configData[id][key] !== element[key])) {
                console.warn("Difference detected between live and schedule. Channel " +
                  id +
                  " key: " +
                  key +
                  " sched value: " +
                  element[key]);
              }
            }
            if (element.rpath) {
              configData[id]["spath"] = element.rpath;
            }
          } else {
            configData[id] = element;
            serverData[id] = {};
            configData[id]["spath"] = element.rpath;
          }
        });
      }
      return state.set("configData", Immutable.fromJS(configData))
        .set("serverData", Immutable.fromJS(serverData))
        .set("embedChannelControl", !!action.config.schedembed_show_channel_control)
        .set("embedVideoPlay", action.config.schedembed_video_play !== false)
        .set("embedLivePlay", !!action.config.schedembed_live_play)
        .set("showSeconds", !!action.config.sched_show_seconds);
    case "RETRIEVE-ITEMS":
      let data = Immutable.fromJS(action.data);
      data = data.set("schedule", data.get("schedule")
        .filter((element) => {
          if (data.getIn(["metadata", element.get("guid"), "schedule announce"]) === "0") {
            return false;
          } else if (data.getIn(["metadata", element.get("guid"), "schedule announce"]) === "1") {
            return true;
          }
          return (element.get("item") && !element.get("default"));
        }).map((element) => {
          let itemDisplay = element.get("item").match(/[^/]+/g).pop();
          itemDisplay = itemDisplay.replace(/\.mp4$/, "");
          if (data.getIn(["metadata", element.get("guid")])) {
            let meta = data.getIn(["metadata", element.get("guid")]);
            // Sets display based on existing metadata tags with the following priority:
            // title > program - episode > program > episode > filename
            let display = meta.get("title")
              ? (meta.get("episode")
                ? meta.get("title") + " - " + meta.get("episode")
                : meta.get("title"))
              : (meta.get("program")
                ? (meta.get("episode")
                  ? meta.get("program") + " - " + meta.get("episode")
                  : meta.get("program"))
                : (meta.get("episode")
                  ? meta.get("episode")
                  : itemDisplay));
            return element.set("meta", meta)
              .set("display", display)
              .set("localid", uuid.v4());
          } else {
            return element.set("meta", null)
              .set("display", itemDisplay)
              .set("localid", uuid.v4());
          }
        })).delete("metadata");
      return state.setIn(["serverData", action.channel], data);
    case "CHANGE-VIEW-DATE":
      // Ensure DST does not mess up the view date
      if (state.getIn(["currentView", "viewType"]) === "date") {
        let currentDate = new Date(state.getIn(["currentView", "date"]));
        let newDate = new Date(action.newView.date);
        if(newDate.getTimezoneOffset() !== currentDate.getTimezoneOffset()) {
          let offsetHours = (newDate.getTimezoneOffset() - currentDate.getTimezoneOffset()) / 60
          action.newView.date += (offsetHours * HOURTOMILLIS)
        }
      }
      if (state.getIn(["serverData", action.newView.channel]) &&
         !state.getIn(["serverData", action.newView.channel]).isEmpty()) {
        if (action.newView.date + (DAYTOSECONDS * 1000) >= (state.getIn([
          "serverData",
          action.newView.channel,
          "report",
          "start unix time"]) * 1000) &&
        action.newView.date < (state.getIn([
          "serverData",
          action.newView.channel,
          "report",
          "end unix time"]) * 1000)) {
          let startDate = (action.newView.date / 1000);
          let endDate = (startDate + DAYTOSECONDS);
          state = state.set("currentView", Immutable.Map(action.newView))
            .set("previousDate", action.newView.date)
            .set("previousChannel", action.newView.channel);
          state = state.set("displaySchedule", state.getIn(["serverData", action.newView.channel, "schedule"])
            .filter((element) => {
              return (!(element.get("start unix") > endDate || element.get("end unix") < startDate));
            })
          );
        } else if (action.newView.channel !== state.getIn(["currentView", "channel"]) ||
                  action.newView.force_date) {
          let startDate = (state.getIn(["serverData", action.newView.channel, "report", "unix time"]));
          let endDate = (startDate + DAYTOSECONDS);
          action.newView.date = startDate * 1000;
          state = state.set("currentView", Immutable.Map(action.newView))
            .set("previousDate", action.newView.date);
          state = state.set("displaySchedule", state.getIn(["serverData", action.newView.channel, "schedule"])
            .filter((element) => {
              return (!(element.get("start unix") > endDate || element.get("end unix") < startDate));
            })
          );
        }
      } else {
        state = state.set("currentView", Immutable.Map(action.newView))
          .set("previousDate", action.newView.date)
          .set("previousChannel", action.newView.channel)
          .set("displaySchedule", []);
      }
      return state;
    case "CHANGE-VIEW-SEARCH":
      if (action.newView.query) {
        let displayItems = Immutable.List([]);
        state.get("serverData").forEach((element, channel) => {
          channel = "" + channel;
          if (element.get("schedule")) {
            displayItems = displayItems.concat(element.get("schedule").filter((element) => {
              return element.get("display").toLowerCase().includes(action.newView.query.toLowerCase());
            }).map((element) => {
              if (state.getIn(["configData", channel, "filename"])) {
                return element.set("channel", state.getIn(["configData", channel, "filename"]));
              } else {
                return element.set("channel", "Channel " + channel);
              }
            }));
          }
        });
        state = state.set("currentView", Immutable.Map(action.newView))
          .set("displaySchedule", displayItems);
      }
      return state;
    case "SCHEDULE-LOAD-ERROR":
      let {channel} = action;
      if (state.getIn(["serverData", channel])) {
        state = state.deleteIn(["serverData", channel]);
      }
      return state;
    case "SET-LIVE-VIEWERS": {
      let {data} = action;
      return state.set("liveViewers", data);
    }
    default:
      return state;
  }
};
