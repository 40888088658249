import React from "react";

import VideoInfoText from "./VideoInfoText.js";
import ClientLogo from "./ClientLogo.js";
import WidgetInfoModal from "./WidgetInfoModal.js";
import ShareModal from "./ShareModal.js";

import "./VideoInfo.css";

/**
* Video info bar component for the widget.
* @prop {object} data State from VideoInfoDuck
* @prop {function} toggleWidgetInfoModal Function passed to children.
*/
export default class VideoInfo extends React.PureComponent {
  /**
  * Renders a video info bar, which contains text that displays a welcome message or the title of what's playing,
  * social media share buttons, and a client logo that can be clicked on to bring up a widget info modal.
  */
  render () {
    let cls = "SocialMediaShareButton";
    let shareFunction = () => {};
    let showViews = this.props.data.get("showViewCounts");
    if (this.props.data.get("currentRpath")) {
      shareFunction = this.props.toggleShareModal;
    } else {
      cls = "SocialMediaShareButton disabled";
    }
    let viewLogo = this.props.data.get("viewLogo");
    let liveRpath = /@(live):(ch\d+)/.exec(this.props.data.get("currentRpath"));
    if (liveRpath && liveRpath.length === 3) {
      let spath = `/${liveRpath[1]}/${liveRpath[2]}.m3u8`;
      let viewerCount = this.props.liveViews.get(spath) || 0;
      viewLogo = `${viewerCount}${viewLogo}`;
      showViews = this.props.data.get("showLiveViews");
    }
    return (
      <div id='video-info'>
        <VideoInfoText title={this.props.data.get("title")}
          showViews={showViews}
          viewLogo={viewLogo} />
        <div className={cls} onClick={shareFunction}>
          <img src={"share.svg"} className='socialIcon' alt="Share"/>
        </div>
        <ClientLogo logo={this.props.data.get("logo")}
          toggleWidgetInfoModal={this.props.toggleWidgetInfoModal} />
        <WidgetInfoModal display={this.props.data.get("display")}
          version={this.props.data.get("version")}
          toggleWidgetInfoModal={this.props.toggleWidgetInfoModal} />
        <ShareModal display={this.props.data.get("showShareModal")}
          toggleShareModal={this.props.toggleShareModal}
          rpath={this.props.data.get("currentRpath")} />
      </div>
    );
  }
}
