// vim: et sw=2 ts=2 sts=2
import Immutable from "immutable";
import {actions as CommonActions} from "./CommonActions.js";
import uuid from "uuid";

// Action Creators
export const actions = {

  ccInitialize: () => {
    return (dispatch, getState) => {
      if (window.cast) {
        window.cast.framework.CastContext.getInstance().setOptions({
          autoJoinPolicy: window.chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
          receiverApplicationId:
            window.chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID
        });
        let ccPlayer = new window.cast.framework.RemotePlayer();
        let ccPlayerController = new window.cast.framework.RemotePlayerController(ccPlayer);
        ccPlayerController.addEventListener(
          window.cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
          () => { dispatch(actions.ccChangeConnected(ccPlayer.isConnected)); }
        );
        dispatch({
          type: "CC-INITIALIZE",
          ccPlayer,
          ccPlayerController
        });
      }
    };
  },

  ccChangeConnected: (connected) => ({
    type: "CC-CHANGE-CONNECTED",
    connected
  }),

  /**
  * Synchronous passthrough called by populateQueueAsync.
  * @param {array} data An array of files to populate the queue with.
  */
  populateQueue: (data) => {
    return {
      type: "POPULATE-QUEUE",
      data
    };
  },

  /**
  * Sets the index that denotes the current video within the queue that is playing.
  * @param {number} index The new index to set to.
  */
  setQueueIndex: (index) => {
    return {
      type: "SET-QUEUE-INDEX",
      index
    };
  },

  /**
  * If there is file in the queue after the currently playing video, it plays that one. Otherwise, it does nothing.
  */
  playNext: () => {
    return (dispatch, getState) => {
      let list = getState().BrowsingSectionDuck.get("displayItems").getIn(
        getState().VideoPlayerDuck.get("queue").toJS()
      );
      if (Immutable.List.isList(list) && list.size > 0) {
        if (getState().VideoPlayerDuck.get("queueIndex") + 1 < list.size) {
          dispatch(CommonActions.playVideoSync(list.get(getState().VideoPlayerDuck.get("queueIndex") + 1)));
          dispatch(actions.setQueueIndex(getState().VideoPlayerDuck.get("queueIndex") + 1));
        } else if (getState().VideoPlayerDuck.get("loop") &&
          getState().VideoPlayerDuck.get("queueIndex") + 1 >= list.size) {
          dispatch(CommonActions.playVideoSync(list.get(0)));
          dispatch(actions.setQueueIndex(0));
        }
      }
    };
  },

  /**
  * Jumps to a specific time in the video.
  * @param {number} time The time to jump to in seconds.
  */
  gotoTime: (time) => {
    return {
      type: "GOTO-TIME",
      time
    };
  },

  /**
  * Sets a boolean indicating that the basic html5 video player has encountered an error,
  *   and that the iframe video fallback should be used.
  */
  htmlVideoNotSupported: () => {
    return {
      type: "HTML-VIDEO-NOT-SUPPORTED"
    };
  },

  /**
  * Sets a boolean indicating that the iframe video player has encountered an error,
  *   and that the flash player fallback should be used.
  */
  iframeVideoNotSupported: () => {
    return {
      type: "IFRAME-VIDEO-NOT-SUPPORTED"
    };
  },

  /**
  * Asynchronously retrieves a playcount token from the playcount backend, and resets the playcount time ranges.
  * @param {string} rpath The rpath of the video.
  */
  videoLoadStartAsync: (rpath) => {
    return async (dispatch, getState) => {
      if (rpath) {
        let source = "play-count";
        if (getState().VideoPlayerDuck.get("playMode") === "live") {
          source = "live-watch";
        }
        let url = new URL(rpath, document.location).pathname;
        url = url.replace(/^(\/files\/|\/vod(i?)\/dl\.php\/)/, "");
        let res = await fetch("/api/v1/vod/" + source + "/get-token", {
          method: "POST",
          headers: {
            'Content-Type': "application/json; charset=UTF-8"
          },
          body: JSON.stringify({
            rpath: decodeURIComponent(url)
          })
        })
        if(res.ok) {
          let data = await res.json()
          dispatch(actions.videoLoadStart(data.token));
        } else {
          let err = await res.text()
          console.error(err);
        }
      } else {
        console.warn("No rpath (Ignore this if page is initializing)");
      }
    };
  },

  /**
  * Synchronous passthrough called by videoLoadStartAsync.
  * @param token The unique token retrieved from the playcount backend.
  */
  videoLoadStart: (token) => {
    return {
      type: "VIDEO-LOAD-START",
      token
    };
  },

  /**
  * First, updates the currently tracked playcount timeranges.
  *   Then, if 10 seconds have passed since last send or on force, sends the playcount
  *   timeranges to the backend playcount api and updates the timestamp used
  *   to determine when next to send timeranges.
  * @params {object} timeranges The ranges of time in the video that have been played.
  *   Special object retrieved from video player.
  * @params {number} timestamp The current time in milliseconds
  * @params {boolean} force If true, forces the timeranges to be sent to the backend regardless of video time.
  */
  videoPlaycount: (timeranges, timestamp, force) => {
    return (dispatch, getState) => {
      dispatch(actions.videoPlaycountUpdate(timeranges));
      let source = "play-count";
      if (getState().VideoPlayerDuck.get("playMode") === "live") {
        source = "live-watch";
      }
      if ((timestamp - getState().VideoPlayerDuck.get("playcountTimestamp") > 10000 || force) &&
         getState().VideoPlayerDuck.get("playcountObject").length > 0) {
        let arr = [];
        let sendranges = getState().VideoPlayerDuck.get("playcountObject");
        if (sendranges) {
          if (sendranges.ranges) {
            arr = sendranges.ranges;
          } else {
            for (let i = 0; i < sendranges.length; ++i) {
              arr.push({
                start: sendranges.start(i),
                end: sendranges.end(i)
              });
            }
          }
        }
        fetch("/api/v1/vod/" + source + "/" + getState().VideoPlayerDuck.get("playcountToken"), {
          method: "PUT",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Accepts": "application/json"
          },
          body: JSON.stringify({played: arr})
        })
        dispatch(actions.videoPlaycountTimestampUpdate(timestamp));
      }
    };
  },

  /**
  * Updates the played timeranges of the current video.
  * @params {object} The played timeranges of the current video. Special object retrieved from video player.
  */
  videoPlaycountUpdate: (timeranges) => {
    return {
      type: "VIDEO-PLAYCOUNT",
      timeranges
    };
  },

  /**
  * Updates the current timestamp used to determine when to send the playcount results.
  * @params {number} timestamp Current time in milliseconds.
  */
  videoPlaycountTimestampUpdate: (timestamp) => {
    return {
      type: "VIDEO-TIMESTAMP",
      timestamp
    };
  },

  /**
  * Sets whether videos should autoplay when loaded.
  * @params {boolean} value Boolean represnting whether videos should autoplay or not by default.
  */
  videoShouldAutoplay: (value) => {
    return {
      type: "VIDEO-SHOULD-AUTOPLAY",
      value
    };
  },

  /**
   * Sets whether playlist/program video queue should loop
   * @params {boolean} value Boolean representing whether to loop the video queue or not
   */
  setQueueLoop: (value) => {
    return {
      type: "SET-QUEUE-LOOP",
      value
    };
  },

  /**
   * Sets data about the video player, which can be used by the controls
   * @params {object} data Data to be merged in to the current video data object
   */
  setVideoData: (data) => {
    return {
      type: "SET-VIDEO-DATA",
      data
    };
  }
};

// Reducers
export const initialState = Immutable.Map({
  queue: Immutable.List([]), // List containing queue of videos within playlist or program to play in sequence
  queueIndex: 0, // Current video in queue to play
  videoURL: "", // URL of current video to play
  uuid: "", // uuid used in order to force player to reload. Changes on video play.
  autoplay: false, // Whether video should autoplay or not
  shouldAutoplay: true, // Whether autoplay should be enabled on playing a video or not, as determined by url param
  timeEvent: Immutable.Map({time: 0, id: 0}), // Event object used to set video current time
  htmlVideoSupported: true, // Whether the current video is supported by html5 video
  iframeVideoSupported: true, // Whether to use iframe fallback. If false, will try flash fallback.
  playcountToken: "", // Token of video for playcount usage
  playcountObject: {length: 0}, // Object for playcount. NOTE: Cannot be converted to immutable
  playcountTimestamp: Date.now(), // Timestamp used to determine when to send put request to playcount
  endTime: -1, // Endtime of video
  contextMenu: false, // whether to allow context menu for video
  contextMenuDefault: false, // The config value to fall back to for videos without a downloadable metadata tag
  livePoster: "", // Poster for live feed
  noDownload: true, // Whether to disable download button for video player on Chrome or not
  noDownloadDefault: true, // The config value to fall back to for videos without a downloadable metadata tag
  playMode: "", // Whether the video player is playing a live stream ('live') or a video ('video').
  loop: false, // If true, queue will loop
  videoData: Immutable.Map({}), // Data about the video player that is used by the video controls
  // CHROMECAST STUFF
  ccPlayer: null, // Chromecast remotePlayer instance
  ccPlayerController: null, // Chromecast remotePlayerController instance
  connected: false // True if connected to chromecast
});
export default (state = initialState, action) => {
  switch (action.type) {
    case "CC-INITIALIZE":
      return state.set("ccPlayer", action.ccPlayer)
        .set("ccPlayerController", action.ccPlayerController);
    case "CC-CHANGE-CONNECTED":
      return state.set("connected", action.connected);
    case "INITIALIZE-CONFIG":
      if (action.config.allow_context_menu !== undefined) {
        state = state.set("contextMenu", action.config.allow_context_menu)
          .set("contextMenuDefault", action.config.allow_context_menu);
      }
      if (action.config.noDownload === false) {
        state = state.set("noDownload", false)
          .set("noDownloadDefault", false);
      }
      return state;
    case "PLAY-VIDEO":
      // assign rpath to videoURL in state
      // assign autoplay to true
      let outTime = -1;
      let url = window.location.protocol + "//" + window.location.host + "/vod/dl.php/" + action.data.rpath;
      if (action.data.meta.in || action.data.meta.out) {
        url = url + "#t=";
        if (action.data.meta.in) {
          url = url + action.data.meta.in;
        }
        if (action.data.meta.out) {
          url = url + "," + action.data.meta.out;
          outTime = action.data.meta.out;
        }
      }
      if (action.data.meta.downloadable === "false" ||
         action.data.meta.downloadable === "0" ||
         action.data.meta.downloadable === "no") {
        state = state.set("noDownload", true)
          .set("contextMenu", false);
      } else if (action.data.meta.downloadable === "true" ||
                action.data.meta.downloadable === "1" ||
                action.data.meta.downloadable === "yes") {
        state = state.set("noDownload", false)
          .set("contextMenu", true);
      } else {
        state = state.set("noDownload", state.get("noDownloadDefault"))
          .set("contextMenu", state.get("contextMenuDefault"));
      }
      return state.set("videoURL", url)
        .set("autoplay", state.get("shouldAutoplay"))
        .set("uuid", uuid.v4())
        .set("htmlVideoSupported", true)
        .set("iframeVideoSupported", true)
        .set("endTime", outTime)
        .set("livePoster", "")
        .set("playMode", "video")
        .set("playcountToken", null)
        .set("playcountObject", {length: 0});
    case "PLAY-LIVE":
      return state.set("videoURL", action.data.live.hls)
        .set("livePoster", action.data.poster)
        .set("htmlVideoSupported", true)
        .set("iframeVideoSupported", true)
        .set("autoplay", state.get("shouldAutoplay"))
        .set("playMode", "live")
        .set("playcountToken", null)
        .set("playcountObject", {length: 0});
    case "POPULATE-QUEUE":
      if (action.data) {
        return state.set("queue", action.data.concat(["list"]));
      } else {
        return state.set("queue", Immutable.List([]));
      }
    case "SET-QUEUE-INDEX":
      return state.set("queueIndex", action.index);
    case "GOTO-TIME":
      return state.setIn(["timeEvent", "time"], action.time)
        .setIn(["timeEvent", "id"], uuid.v4());
    case "HTML-VIDEO-NOT-SUPPORTED":
      return state.set("htmlVideoSupported", false);
    case "IFRAME-VIDEO-NOT-SUPPORTED":
      return state.set("iframeVideoSupported", false);
    case "VIDEO-LOAD-START":
      return state.set("playcountToken", action.token)
        .set("playcountObject", {length: 0});
    case "VIDEO-PLAYCOUNT":
      return state.set("playcountObject", action.timeranges);
    case "VIDEO-TIMESTAMP":
      return state.set("playcountTimestamp", action.timestamp);
    case "VIDEO-SHOULD-AUTOPLAY":
      return state.set("shouldAutoplay", action.value);
    case "SET-QUEUE-LOOP":
      return state.set("loop", action.value);
    case "SET-VIDEO-DATA":
      return state.set("videoData", state.get("videoData").merge(action.data));
    default:
      return state;
  }
};
