// vim: et sw=2 ts=2 sts=2
import Immutable from "immutable";
import config from "config";

// Action Creators
export const actions = {

  /**
  * @function toggleWidgetInfoModal
  * Toggles the modal that displays information about the whole widget (just the version number right now).
  */
  toggleWidgetInfoModal: () => {
    return {
      type: "TOGGLE-WIDGET-INFO-MODAL"
    };
  },

  /**
  * @function toggleShareModal
  * Toggles the modal that displays sharing options for the current video
  */
  toggleShareModal: () => {
    return {
      type: "TOGGLE-SHARE-MODAL"
    };
  }
};

// Reducers
export const initialState = Immutable.Map({
  title: "Please Select a Video to Play!",  // Title to display in title bar
  viewLogo: "",                             // View count label
  logo: "",                                 // url of logo
  display: false,                           // Whether to display widget info modal or not
  version: config.version,                  // Current version of VOD Widget
  currentRpath: "",                         // Rpath for making url for social media
  showShareModal: "",                       // Whether to display share modal or not
  showViewCounts: true,                     // Whether to display view counts or not
  showLiveViews: true,                      // Whether to display live viewership or not
});
export default (state = initialState, action) => {
  switch (action.type) {
    case "INITIALIZE-CONFIG":
      if (action.config.header_logo) {
        state = state.set("logo", action.config.header_logo);
      }
      if (action.config.welcome_message) {
        state = state.set("title", action.config.welcome_message);
      }
      if (action.config.noViewcounts) {
        state = state.set("showViewCounts", !action.config.noViewcounts);
      }
      if (action.config.noLiveviews) {
        state = state.set("showLiveViews", !action.config.noLiveviews);
      }
      return state;
    case "PLAY-VIDEO":
      let file = action.data;
      let views = file.playcount || 0;
      let es = views !== 1 && views !== "1" ? "s" : "";
      state = state.set("viewLogo", "" + views + " View" + es);
      if (file.meta.title && file.meta.title !== "") {
        return state.set("title", file.meta.title).set("currentRpath", action.data.rpath);
      } else if (file.meta.program && file.meta.program !== "" &&
                file.meta.episode && file.meta.episode !== "") {
        return state.set("title", (file.meta.program + " - " + file.meta.episode))
          .set("currentRpath", action.data.rpath);
      } else if (file.meta.program && file.meta.program !== "") {
        return state.set("title", file.meta.program).set("currentRpath", action.data.rpath);
      } else if (file.meta.episode && file.meta.episode !== "") {
        return state.set("title", file.meta.episode).set("currentRpath", action.data.rpath);
      } else {
        return state.set("title", file.origName).set("currentRpath", action.data.rpath);
      }
    case "PLAY-LIVE":
      return state.set("title", action.data.filename)
        .set("currentRpath", "@live:" + action.data.id)
        .set("viewLogo", " Currently Viewing");
    case "TOGGLE-WIDGET-INFO-MODAL":
      return state.set("display", !state.get("display"));
    case "TOGGLE-SHARE-MODAL":
      return state.set("showShareModal", !state.get("showShareModal"));
    default:
      return state;
  }
};
