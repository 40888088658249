import Immutable from "immutable";

var exports = {};

/**
* Returns the name to be displayed for a live item as a string.
* @param {object} file An object containing information about a live item.
* @param {object} file.meta The metadata member of the live item.
* @param {object} file["original filename"] The filename of the live item, extracted from the rpath.
* @returns A string that contains the name to be displayed.
*   Tries to display, in order of priority, "title", "program - episode", "program", "episode", "filename".
*/
exports.constructLiveItemName = (file) => {
  let title;
  // Calculate Title
  if (file.meta.get("title") && file.meta.get("title") !== "") {
    title = file.meta.get("title");
  } else if (file.meta.get("program") && file.meta.get("program") !== "" &&
            file.meta.get("episode") && file.meta.get("episode") !== "") {
    title = file.meta.get("program") + " - " + file.meta.get("episode");
  } else if (file.meta.get("program") && file.meta.get("program") !== "") {
    title = file.meta.get("program");
  } else if (file.meta.get("episode") && file.meta.get("episode") !== "") {
    title = file.meta.get("episode");
  } else {
    if (file["original filename"].lastIndexOf(".") !== -1) {
      title = file["original filename"].slice(0, file["original filename"].lastIndexOf("."));
    } else {
      title = file["original filename"];
    }
  }
  return title;
};

/**
* @typedef {Object} displayItem
* @property {String} title The title to display of the item.
* @property {String} description The description to display of the item.
* @property {String} rpath The rpath of the item.
* @property {String} thumbnailRpath The rpath of the item's thumbnail.
* @property {String} guid For files, the guid of the file.
* @property {String} type The type of the item (either file, program, or playlist)
* @property {Object} meta For files, the metadata of the file directly passed through.
*/

/**
* Returns an immutable object that is used to hold file information to display in the BrowsingSection
* @param {object} file The file that the information is coming from, retrieved from the backend.
* @returns {displayItem} Information about the item to be displayed in the BrowsingSection.
*/
exports.constructDisplayFile = (file) => {
  let title,
    subtitle,
    rpath,
    thumbnailRpath,
    guid,
    type,
    meta,
    origName,
    agenda,
    playcount,
    hidden,
    key;
  if(file.metadata && !file.meta) {
    file.meta = file.metadata
  }
  // Calculate Title
  if (file.meta.title && file.meta.title !== "") {
    title = file.meta.title;
  } else if (file.meta.program && file.meta.program !== "") {
    title = file.meta.program;
  } else if (file.meta.episode && file.meta.episode !== "") {
    title = file.meta.episode;
  } else {
    if (file["original filename"].lastIndexOf(".mp4") !== -1) {
      title = file["original filename"].slice(0, file["original filename"].lastIndexOf("."));
    } else {
      title = file["original filename"];
    }
  }
  // Calculate Description
  if (file.meta.episode && file.meta.episode !== "" &&
     title !== file.meta.episode) {
    subtitle = file.meta.episode;
  } else if (file.meta.summary) {
    subtitle = file.meta.summary;
  } else {
    subtitle = null;
  }
  // Assign Rpath
  rpath = file.rpath;
  if(typeof rpath === 'object') {
    rpath = rpath.label
  }
  // Assign Thumbnail rpath
  thumbnailRpath = "/api/v1/api/assets/thumbnail/" + rpath;
  // Calculate guid
  if (file.meta.guid) {
    guid = file.meta.guid.substring(1, file.meta.guid.indexOf("}"));
  }
  // Assign Type
  type = "video";
  // Assign Metadata
  meta = file.meta; // TODO: Can this be converted to Immutable?
  // Remember original filename
  if (file["original filename"].lastIndexOf(".mp4") !== -1) {
    origName = file["original filename"].slice(0, file["original filename"].lastIndexOf("."));
  } else {
    origName = file["original filename"];
  }
  // Assign Agenda
  agenda = file.agenda;
  // Assign Playcount
  if(typeof file.playcount === 'object') {
    playcount = file.playcount.label
  } else {
    playcount = file.playcount || 0;
  }
  // Check Hidden
  let hideValue = file.meta.hidden;
  if (hideValue) {
    hideValue = hideValue.toLowerCase();
    hidden = (hideValue === "1" || hideValue === "yes" || hideValue === "true");
  }
  // Assign Key
  key = rpath;
  // Return object
  return Immutable.Map({
    title,
    subtitle,
    rpath,
    thumbnailRpath,
    guid,
    type,
    meta,
    origName,
    agenda,
    playcount,
    hidden,
    key
  });
};

/**
* Returns an immutable object that is used to hold program information to display in the BrowsingSection
* @param {object} file The original information about the program to be displayed, retrieved from the backend.
* @param {number} size The number of videos in the program to be displayed.
* @returns {displayItem} The information about the program to be displayed in the BrowsingSection.
*/
exports.constructDisplayProgram = (file, size) => {
  let title,
    subtitle,
    rpath,
    thumbnailRpath,
    type,
    hidden,
    key;
  // Assign Title
  title = file.name;
  // Assign Description
  subtitle = "Videos: " + size;
  // Assign Rpath
  rpath = file.name;
  // Assign Thumbnail rpath
  thumbnailRpath = "/files/" + file.thumbnail;
  // Assign Type
  type = "program";
  // Assign Key
  key = file.name;
  // Check hidden
  hidden = (size <= 0);
  // Return object
  return Immutable.Map({
    title,
    subtitle,
    rpath,
    thumbnailRpath,
    type,
    hidden,
    key
  });
};

/**
* Returns an immutable object that is used to hold playlist information to display in the BrowsingSection
* @param {Object} file The original playlist to be displayed, retrieved from the backend.
* @returns {displayItem} The information about the playlist to be displayed in the BrowsingSection.
*/
exports.constructDisplayPlaylist = (file) => {
  let title, subtitle, rpath, thumbnailRpath, type, key;
  // Assign Title
  title = file.filename;
  title = title.split(".");
  title = title.slice(0, title.length - 1).join(".");
  // Assign Description
  subtitle = null;
  // Assign Rpath
  rpath = file.rpath;
  // Assign Thumbnail Rpath
  thumbnailRpath = "/vod/thmb.php/" + file.rpath;
  // Assign Type
  type = "playlist";
  // Assign Key
  key = file.rpath;
  // return object
  return Immutable.Map({
    title,
    subtitle,
    rpath,
    thumbnailRpath,
    type,
    key
  });
};

/**
* Returns "/vod/" in production and "" otherwise. "/vod/" may be the cause of the double slash bug?
* @returns {String} "/vod/" in production, "" otherwise.
*/
exports.urlBase = () => {
  if (process.env.NODE_ENV === 'production') {
    return "/vod";
  } else {
    return "";
  }
};

exports.parseHash = (urlParams) => {
  if (!window.location.hash) {
    return urlParams;
  }
  let auto = /autoplay=[01]/.exec(window.location.hash);
  if (auto && auto[0] && auto[0].slice(-1) === "0") {
    urlParams.noautoplay = "1";
  }
  let gui = /nogui=[01]/.exec(window.location.hash);
  if (gui && gui[0] && gui[0].slice(-1) === "1") {
    if (urlParams.schedule) {
      urlParams.view = 19;
    } else {
      urlParams.view = 3;
    }
  }
  return urlParams;
};

exports.mergeInBrowsingItems = (oldItems, newItems, start, perPage) => {
  while (oldItems.size < start) {
    oldItems = oldItems.push(undefined);
  }
  return oldItems.splice(start, perPage, ...newItems);
};

export default exports;
